import React, { useCallback, useEffect, useRef, useState } from "react";
import lottie, { type AnimationItem } from "lottie-web";
import hamburgerAnimationData from "../../../../public/animations/hamburger.json";

interface Props {
    id: string;
}

const HamburgerMenuIcon: React.FC<Props> = props => {
    const container = useRef<HTMLDivElement | null>(null);
    let animation: AnimationItem | undefined;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (container.current) {
            animation = lottie.loadAnimation({
                container: container.current,
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: hamburgerAnimationData
            });

            return () => {
                animation?.destroy();
            };
        }
    }, []);

    const handleClick = useCallback((newIsOpen: boolean) => {
        if (newIsOpen) {
            animation?.setDirection(1);
            animation?.play();
        }

        if (!newIsOpen) {
            animation?.setDirection(-1);
            animation?.play();
        }

        setIsOpen(newIsOpen);
    }, []);

    return (
        <div
            className="flex justify-center items-center h-full w-full"
            aria-hidden="true"
            onClick={() => {
                handleClick(!isOpen);
            }}
            id={props.id}
        >
            <div
                ref={container}
                style={{ width: "24px", height: "24px" }}
            ></div>
        </div>
    );
};

export default HamburgerMenuIcon;
